/* eslint-disable no-self-compare */
import React, { useEffect, useState } from "react";
import SocialSidebar from "./SocialSidebar";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../Config/AxiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Layout from "./Layout/Layout";
import axios from "axios";
// const formatPhoneNumber = (value) => {
//   // Remove all non-digit characters
//   const digits = value.replace(/\D/g, "");

//   // Apply the formatting
//   if (digits.length <= 4) {
//     return digits;
//   } else if (digits.length <= 7) {
//     return `${digits.slice(0, 4)}-${digits.slice(4)}`;
//   } else {
//     return `${digits.slice(0, 4)}-${digits.slice(4, 7)}-${digits.slice(7, 10)}`;
//   }
// };
function Profile() {
  const selectStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [, setDistricts] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  console.log(selectedDistrict, "selectedDistrict");
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);
  const [image, setImage] = useState("");
  const [isVerified, setIsVerified] = useState(false); // Added state for verification

  const geonamesUsername = "sywyj";
  const navigate = useNavigate();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (data?.is_verified === "1" || data?.is_verified === 1) {
      setIsVerified(true); // Set the verification status
    }
  }, []);

  useEffect(() => {
    if (1 === 1) {
      const fetchStates = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${2300660}&username=${geonamesUsername}`
          );
          const stateOptions = response.data.geonames.map((state) => ({
            value: state.geonameId,
            label: state.name,
          }));
          setStates(stateOptions);
        } catch (error) {
          setError("Error fetching states");
          console.error("Error fetching states:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStates();
    } else {
      setStates([]);
      setCities([]);
      setDistricts([]);
    }
  }, []);

  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedState.value}&username=${geonamesUsername}`
          );
          const cityOptions = response.data.geonames.map((city) => ({
            value: city.geonameId,
            label: city.name,
          }));
          setCities(cityOptions);
        } catch (error) {
          setError("Error fetching cities");
          console.error("Error fetching cities:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCities();
    } else {
      setCities([]);
      setDistricts([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      const fetchDistricts = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedCity.value}&username=${geonamesUsername}`
          );
          const districtOptions = response.data.geonames.map((district) => ({
            value: district.geonameId,
            label: district.name,
          }));
          setDistricts(districtOptions);
        } catch (error) {
          setError("Error fetching districts");
          console.error("Error fetching districts:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
    }
  }, [selectedCity]);

  const data = JSON.parse(localStorage.getItem("user"));
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    // phone: Yup.string()
    //   .matches(/^\d{4}-\d{3}-\d{3}$/, "Phone number is not valid")
    //   .required("Phone Number is required"),
    // w_phone: Yup.string().matches(
    //   /^\d{4}-\d{3}-\d{3}$/,
    //   "WhatsApp number is not valid"
    // ),
    // second_phone: Yup.string().matches(
    //   /^\d{4}-\d{3}-\d{3}$/,
    //   "Verification number is not valid"
    // ),
    w_phone: Yup.string()
    .matches(/^233\d{9}$/, 'Wahstapp number must start with 233 and be exactly 12 digits long')
    .required('Phone number is required'),
    phone: Yup.string()
    .matches(/^233\d{9}$/, 'Phone number must start with 233 and be exactly 12 digits long')
    .required('Phone number is required'),
    second_phone: Yup.string()
      .matches(/^233\d{9}$/,
        "Verification number must start with 233 and be exactly 12 digits long"
      )
      .required("Verification Number is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    country: Yup.string(),
    region: Yup.string(),
    district: Yup.string(),
    city: Yup.string().required("City is required"),

    suburb: Yup.string(),
  });

  const initialValues = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    country: data?.country || "",
    phone: data?.phone || "",
    w_phone: data?.w_phone || "",
    second_phone: data?.second_phone || "",
    region: data?.region || "",
    district: data?.district || "",
    city: data?.city || "",
    suburb: data?.suburb || "",
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();
    setSubmitting(true);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("phone", values.phone);
    formData.append("w_phone", values.w_phone);
    formData.append("second_phone", values.second_phone);
    formData.append("district", selectedCity?.label);
    formData.append("region", selectedState?.label);
    formData.append("city", values?.city);
    formData.append("image", image);
    // formData.append("city", values.city);
    formData.append("suburb", values.suburb);
    formData.append("role", data.role);

    axiosInstance
      .post("/profile/update", formData)
      .then((res) => {
        if (res?.data?.status === true) {
          setSubmitting(false);
          localStorage.setItem("user", JSON.stringify(res?.data?.user));
          toast.success(res?.data?.message);
          resetForm();
          navigate("/en-gh");
        } else {
          toast.error(res?.data?.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div>
      <Layout>
        <section
          id="user-profile"
          className="user-profile"
          style={{ marginTop: "0px" }}
        >
          <div className="container">
            <div className="row">
              <SocialSidebar />
              <div className="col-xs-12 col-sm-12 col-md-8">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form className="mb-0">
                      <div className="form-box">
                        <h4 className="form--title">Personal Details</h4>

                        <div className="form-group">
                          <label htmlFor="first_name">First Name</label>
                          <Field
                            type="text"
                            id="first_name"
                            className="form-control"
                            name="first_name"
                            placeholder="First Name"
                            disabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="last_name">Last Name</label>
                          <Field
                            type="text"
                            id="last_name"
                            className="form-control"
                            name="last_name"
                            placeholder="Last Name"
                            disabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            id="email"
                            className="form-control"
                            name="email"
                            placeholder="Email"
                            disabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="phone">Phone Number</label>
                          <Field
                            type="text"
                            id="phone"
                            className="form-control"
                            name="phone"
                            placeholder="Enter Number"
                            disabled={isVerified}
                            maxLength="12"
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                              if (!value.startsWith('233')) {
                                // eslint-disable-next-line prefer-template
                                value = '233' + value.slice(3); // Ensure the value always starts with '233'
                              }
                              if (value.length > 12) {
                                value = value.slice(0, 12); // Ensure the value does not exceed 12 characters
                              }
                              setFieldValue('phone', value); // Update the value
                            }}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="w_phone">WhatsApp Number</label>
                          <Field
                            type="text"
                            id="phone"
                            className="form-control"
                            name="w_phone"
                            disabled={isVerified}
                            placeholder="Enter Number"
                            maxLength="12"
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                              if (!value.startsWith('233')) {
                                // eslint-disable-next-line prefer-template
                                value = '233' + value.slice(3); // Ensure the value always starts with '233'
                              }
                              if (value.length > 12) {
                                value = value.slice(0, 12); // Ensure the value does not exceed 12 characters
                              }
                              setFieldValue('w_phone', value); // Update the value
                            }}
                            // value={field.value}
                          />
                          <ErrorMessage
                            name="w_phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="second_phone">
                            Verification Number
                          </label>
                          <Field
                            type="text"
                            id="second_phone"
                            className="form-control"
                            name="second_phone"
                            placeholder="Enter Number"
                            maxLength="12"
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                              if (!value.startsWith('233')) {
                                // eslint-disable-next-line prefer-template
                                value = '233' + value.slice(3); // Ensure the value always starts with '233'
                              }
                              if (value.length > 12) {
                                value = value.slice(0, 12); // Ensure the value does not exceed 12 characters
                              }
                              setFieldValue('second_phone', value); // Update the value
                            }}
                            disabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage
                            name="second_phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        {/* 
                        <div className="form-group">
                          <label htmlFor="country">Country</label>
                          <Field
                            type="text"
                            id="country"
                            className="form-control"
                            name="country"
                            placeholder="Country"
                            disabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage name="country" component="div" className="text-danger" />
                        </div> */}

                        <div className="form-group">
                          <label htmlFor="region">Region</label>

                          <Select
                            id="region"
                            options={states}
                            // value={selectedState}
                            defaultValue={{
                              label: data?.region,
                              value: data?.region,
                            }}
                            onChange={(option) => {
                              setSelectedState(option);
                              setSelectedCity(null);
                              setSelectedDistrict(null);
                            }}
                            placeholder="Select Region"
                            styles={selectStyles}
                            isDisabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage
                            name="region"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="distirctt">Disrtict</label>
                          <Select
                            id="city"
                            options={cities}
                            // value={selectedCity}
                            styles={selectStyles}
                            defaultValue={{
                              label: data?.district,
                              value: data?.district,
                            }}
                            onChange={(option) => {
                              setSelectedCity(option);
                              setSelectedDistrict(null);
                            }}
                            placeholder="Select District"
                            isDisabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="City">City/Town*</label>
                          <Field
                            type="text"
                            className={`form-control `}
                            name="city"
                            id="city"
                            disabled={isVerified}
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="suburb">Suburb</label>
                          <Field
                            type="text"
                            id="suburb"
                            className="form-control"
                            name="suburb"
                            placeholder="Suburb"
                            disabled={isVerified} // Disable if verified
                          />
                          <ErrorMessage
                            name="suburb"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="image">Profile Image</label>
                          <input
                            type="file"
                            id="image"
                            name="image"
                            className="form-control"
                            onChange={(event) =>
                              setImage(event.target.files[0])
                            }
                            disabled={isVerified} // Disable if verified
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting || isVerified} // Disable submit button if form is submitting or verified
                        >
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
}

export default Profile;
