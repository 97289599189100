import React from "react";
import SocialSidebar from "../SocialSidebar";
import { axiosInstance } from "../../Config/AxiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Layout from "../Layout/Layout";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

function Contact() {
  const data = JSON.parse(localStorage.getItem("user"));
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
    .matches(/^233\d{9}$/, 'Phone number must start with 233 and be exactly 12 digits long')
    .required('Phone number is required'),
    email: Yup.string().email("Invalid email").required("Email is required"),
    message: Yup.string().required("Message is required"),
  });
  // const [state, setState] = useState("");
  const initialValues = {
    name: data?.first_name || "",
    email: data?.email || "",
    phone: data?.phone || "",
    message: "",
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    const formData = new FormData();

    setSubmitting(true);
    formData.append("name", data.first_name);
    formData.append("email", data.email);
    formData.append("phone", data?.phone);
    formData.append("message", values.message);

    axiosInstance
      .post("/contact", formData)
      .then((res) => {
        if (res?.data?.status === true) {
          setSubmitting(false);
          toast.success(res?.data?.message);
          // setState('')
          resetForm();
        } else {
          toast.error(res?.data?.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Layout>
        <section
          id="user-profile"
          className="user-profile"
          style={{ marginTop: "0px" }}
        >
          <div className="container">
            <div className="row">
              <SocialSidebar />
              <div className="col-xs-12 col-sm-12 col-md-8">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ isSubmitting, setFieldValue }) => (
                    <Form className="mb-0">
                      <div className="form-box">
                        <h4 className="form--title">Contact Us</h4>

                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <Field
                            type="text"
                            id="name"
                            disabled
                            className="form-control"
                            name="name"
                            placeholder="Name"
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            type="email"
                            disabled
                            id="email"
                            className="form-control"
                            name="email"
                            placeholder="Email"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="Phone Number">Phone Number</label>
                          <Field
                            type="text"
                            id="PhoneNumber"
                            className="form-control"
                            disabled
                            name="phone"
                            placeholder="Enter Number"
                            maxLength="12"
                            onChange={(e) => {
                              let value = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                              if (!value.startsWith('233')) {
                                // eslint-disable-next-line prefer-template
                                value = '233' + value.slice(3); // Ensure the value always starts with '233'
                              }
                              if (value.length > 12) {
                                value = value.slice(0, 12); // Ensure the value does not exceed 12 characters
                              }
                              setFieldValue('phone', value); // Update the value
                            }}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="message">Message</label>
                          <Field
                            as="textarea"
                            id="message"
                            className="form-control"
                            name="message"
                            placeholder="Your message"
                          />
                          <ErrorMessage
                            name="message"
                            component="div"
                            className="text-danger"
                          />
                        </div>

                        {/* <div className="form-group">
                                                    <label htmlFor="phone">Phone</label>
                                                    <PhoneInput
                                                        country={"gh"}
                                                        onlyCountries={['gh']}
                                                        name="phone"
                                                        value={state?.phone}
                                                        onChange={(phone) => setState({ phone })}
                                                    />
                                                </div> */}
                      </div>

                      <button
                        type="submit"
                        className="btn btn--primary btn--block"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting..." : "Submit Message"}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
}

export default Contact;
