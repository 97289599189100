/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useParams } from "react-router-dom";

function Review() {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState(""); // State for text area

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const token = localStorage.getItem("Token");
  const data = JSON.parse(localStorage.getItem("user"));
  const disabled = data?.is_verified == "1" && token;
  const { id } = useParams();
  const handleSubmit = (event) => {
    const formData = new FormData();

    event.preventDefault();
    formData.append("star", rating);
    formData.append("comment", comment);
    formData.append("listing_id", id);

    axiosInstance
      .post("/listing-review", formData)
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(res?.data?.message);
          setRating(0);
          setComment("");
        } else {
          toast.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err?.response?.data?.message);
      });
  };

  return (
    <div className="property-single-leave-review inner-box">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div className="heading">
            <h2 className="heading--title">Leave a Review</h2>
          </div>
        </div>
        {/* .col-md-12 end */}
        <div className="col-xs-12 col-sm-12 col-md-12">
          <form id="post-comment" className="mb-0" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-md-4">
                <div className="form-group">
                  <label>Rating*</label>
                  <div className="property-rating">
                    <Rating
                      onClick={handleRating}
                      initialValue={rating}
                      fillColor="black"
                      disableFillHover
                      allowFraction={true}
                      size={20}
                    />
                  </div>
                </div>
              </div>
              {/* .col-md-4 end */}
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="form-group">
                  <label htmlFor="review-comment">Review*</label>
                  <textarea
                    className="form-control"
                    id="review-comment"
                    rows={2}
                    maxLength={500}
                    required=""
                    value={comment} // Set the value from state
                    onChange={handleCommentChange} // Handle changes
                  />
                </div>
              </div>
              {/* .col-md-12 */}
              <div className="col-xs-12 col-sm-12 col-md-12">
                {disabled ? (
                  <button type="submit" className="btn btn--primary">
                    Submit
                  </button>
                ) : (
                  <button type="submit" className="btn btn--primary" disabled>
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
        {/* .col-md-12 end */}
      </div>
      {/* .row end */}
    </div>
  );
}

export default Review;
