/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
// src/components/Detail.js

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosData } from "../../Config/Axios";
import { toast } from "react-toastify";
import LoaderCard from "../Loader/LoaderCard";
import { AuthContext } from "../../Config/Context/Context";

function HouseCom({ states, cities }) {
  let { filters } = useContext(AuthContext);
  console.log(filters);
  const filter = states.filter(
    (e) => e.value === Number(filters.selectedState)
  );
  const filter1 = cities.filter(
    (e) => e.value === Number(filters.selectedCity)
  );

  // const { state } = useLocation();
  const [, setHeaderColor] = useState("transparent");
  const [grid, setgrid] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  // const token = localStorage.getItem("Token");

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const GetData = () => {
    setLoader(true);

    // Initialize an array to hold query parameters
    const queryParams = [];

    // Add filters to queryParams if they exist
    // if (filters.type) queryParams.push(`type=${filters.type}`);
    // if (filters.sorts) queryParams.push(`sort=${filters.sorts}`);
    if (filters.cityTown) queryParams.push(`city=${filters.cityTown}`);
    if (filters.price) queryParams.push(`price=${filters.price}`);
    if (filters.rentPeriod)
      queryParams.push(`rent_period=${filters.rentPeriod}`);
    if (filters.availability)
      queryParams.push(`availability=${filters.availability}`);
    if (filters.beds) queryParams.push(`bed=${filters.beds}`);
    if (filters.baths) queryParams.push(`bath=${filters.baths}`);

    const stateLabel = filters?.selectedState
      ? filter[0]?.label
      : "Greater+Accra+Region";

    const type = filters.type ? filters.type : "House";
    const sort = filters.sorts ? filters.sorts : "asc";
    const cityLabel = filters.selectedCity ? filter1[0]?.label : "";

    queryParams.push(`region=${stateLabel}`);
    queryParams.push(`type=${type}`);
    queryParams.push(`sort=${sort}`);
    if (cityLabel) queryParams.push(`district=${cityLabel}`);

    const url = `/listing-user?${queryParams.join("&")}`;

    // Make the API request
    axiosData
      .get(url)
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.data);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useEffect(() => {
    GetData();

    window.scrollTo(0, 0);
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 1700) {
        setHeaderColor("blue");
      } else {
        setHeaderColor("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const HandleFav = (id) => {
    if (!user) {
      toast.error("YOU MUST BE VERIFIED TO USE THIS FEATURE. ");
      return;
    }

    const FormDat = new FormData();
    FormDat.append("listing_id", id);
    axiosData
      .post(`/add-to-favourite`, FormDat)
      .then((res) => {
        if (res?.data?.message === "Unsave") {
          toast.success("Unfavorite");
          GetData();
        } else {
          toast.success("Favorite");
          GetData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const PhoneFun = (phone) => {
    if (user.is_verified == 1) {
      window.location.href = `tel:+${phone}`;
    } else {
      toast.error("YOU MUST BE VERIFIED TO USE THIS FEATURE.");
      return;
    }
  };
  const WhatsappFun = (whatspp) => {
    if (user.is_verified == 1) {
      window.location.href = `https://api.whatsapp.com/send?phone=${whatspp}`;
    } else {
      toast.error("YOU MUST BE VERIFIED TO USE THIS FEATURE.");
      return;
    }
  };
  return (
    <>
      <div
        className="properties-split pt-0 pb-0 bg-white"
        id="properties-split"
      >
        <div className="container-fluid">
          <div className="">
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div
                className={`row pt-45 properties-list  ${
                  grid === "grid" ? "listgrid" : "listgrid1"
                }`}
              >
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="properties-filter clearfix">
                    <div className="select--box pull-left">
                      <label>{data?.length} Results Found</label>
                    </div>
                    <div className="view--type pull-right faiz">
                      <a
                        className={grid === "list" ? "active" : ""}
                        href="#"
                        id="switch-list"
                        onClick={() => setgrid("list")}
                      >
                        <i className="fa fa-th-list" />
                      </a>
                      <a
                        className={grid === "grid" ? "active" : ""}
                        href="#"
                        id="switch-grid"
                        onClick={() => setgrid("grid")}
                      >
                        <i className="fa fa-th-large" />
                      </a>
                    </div>
                  </div>
                </div>

                <div
                  className={`properties properties-grid ${
                    grid === "list" ? "newhello1" : "newhello"
                  }`}
                >
                  {loader ? (
                    <LoaderCard /> // Show LoaderCard when loading
                  ) : (
                    data.map((e) => (
                      <div key={e.id} className="col-xs-12 col-sm-12 col-md-12">
                        <div
                          className={`property-item ${
                            grid === "list" ? "listby" : ""
                          }`}
                        >
                          <div className="property--img">
                            <img
                              alt="property image"
                              onClick={() =>
                                navigate(`/en-gh/singlepro/${e.id}`)
                              }
                              className={`img-responsive ${
                                grid === "list" ? "h-274" : "h-200"
                              }`}
                              src={e.listing_images?.[0]?.image}
                            />
                            <span className="property--status">For Rent</span>
                          </div>
                          <div className="property--content">
                            <div className="property--info">
                              <h5 className="property--title">
                                <a href="#">{e.title}</a>
                                <span
                                  className="value"
                                  onClick={() => HandleFav(e?.id)}
                                  style={{ float: "right" }}
                                >
                                  {e?.is_favourite === 1 ? (
                                    <i
                                      className="fa fa-heart"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <i
                                      className="fa fa-heart-o"
                                      style={{
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </span>
                              </h5>
                              <p className="property--location">
                                <i className="fa fa-map-marker" />
                                {e?.city} ,{e?.district}, {e?.region}
                                <span style={{ float: "right" }}>
                                  {e?.created_at}
                                </span>
                              </p>
                              <div className="flex1">
                                <p
                                  className="property--price "
                                  style={{ display: "flex" }}
                                >
                                  GH₵{e?.price } <sup>/month </sup>
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    width: "100%",
                                  }}
                                >
                                  <button
                                    className="bg-black p-xs border-none button-detail"
                                    onClick={() => PhoneFun(e?.host?.phone)}
                                  >
                                    <i
                                      className="fa fa-phone"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                  <button
                                    className="bg-black p-xs ml-xs border-none button-detail"
                                    onClick={() =>
                                      WhatsappFun(e?.host?.w_phone)
                                    }
                                  >
                                    <i
                                      className="fa fa-whatsapp"
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                  {e?.host?.is_verified == "1" && (
                                    <img
                                      style={{
                                        width: "50px",
                                        marginTop: "-3px",
                                      }}
                                      src="https://static.vecteezy.com/system/resources/thumbnails/035/234/043/small_2x/3d-icon-verify-account-png.png"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="property--features">
                              {/* <p>{e?.description.slice(0, 83)}</p> */}
                              <div className="flex1">
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://dve7rykno93gs.cloudfront.net/assets4/img/shower-icon.png"
                                    alt=""
                                  />
                                  <span className="ml-sm bold font-20 text-black ">
                                    {e?.no_of_bathroom}
                                  </span>
                                </div>
                                <div className="">
                                  <img
                                    className="mtt-10"
                                    src="https://dve7rykno93gs.cloudfront.net/assets4/img/bed-icon.png"
                                    alt=""
                                  />
                                  <span className="ml-sm bold font-20 text-black ">
                                    {e?.no_of_bedroom}
                                  </span>
                                </div>
                                <div className="">
                                  <i
                                    class="fa fa-home"
                                    style={{ fontSize: "20px" }}
                                  ></i>
                                  <span className="ml-sm bold font-20 text-black ">
                                    {e?.type}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HouseCom;
