import React from 'react'

import SinglePro from './SinglePro'
import Layout from '../Layout/Layout'

function SingleProperty() {
  return (
    <div>
   <Layout>

        <div className='mt-20'>

        <SinglePro/>
        </div>
   </Layout>
    
    </div>
  )
}

export default SingleProperty