/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Header from "./Header";
import SocialSidebar from "./SocialSidebar";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../Config/AxiosInstance";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";

function Verify() {
  const [data, setData] = useState([]);
  // const [state, setState] = useState("");
  const [selfie, setselfie] = useState(null);
  // eslint-disable-next-line no-unused-vars

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name Is Required"),
    phone: Yup.string()
      .matches(/^\d{4}-\d{3}-\d{3}$/, "Phone number is not valid")
      .required("Phone Number is required"),
    // second_phone: Yup.mixed(),
    selfie: Yup.mixed(),
  });

  const initialValues = {
    name: "",
    // second_phone: "",
    selfie: "",
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (selfie === null) {
      toast.error("Please select all the necessary documents");
      return;
    }
    const formData = new FormData();

    setSubmitting(true);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("name", values?.name);
    formData.append("second_phone", values?.phone);
    formData.append("selfie", selfie);
    formData.append("role", data.role);

    axiosInstance
      .post("/profile/update", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setSubmitting(false);
          toast.success(res?.data?.message);
          resetForm();

          // setState('')
          setselfie(null)
        } else {
          toast.error(res?.data?.message);
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log("Error in signup", err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const navigate = useNavigate();
  const GetData = () => {
    axiosInstance
      .get("/profile", {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.user);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetData();
    const token = localStorage.getItem("Token");
    if (!token) {
      navigate("/");
    } else {
      navigate("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />

      <section
        id="user-profile"
        className="user-profile"
        style={{ marginTop: "0px" }}
      >
        <div className="container">
          <div className="row">
            <SocialSidebar />

            <div className="col-xs-12 col-sm-12 col-md-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="mb-0">
                    <div className="form-box">
                      <h4 className="form--title">Verify Profile</h4>

                      <div className="form-group">
                        <p>Full Name</p>
                        <Field
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Enter Full Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group">
                          <label htmlFor="Phone Number">Phone Number</label>
                          <Field
                            type="text"
                            id="PhoneNumber"
                            className="form-control"
                            name="phone"
                            placeholder="0244-622-816"
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      {/* <div className="form-group">
                        <p>Phone Number</p>
                        <PhoneInput
                          country={"gh"}
                          onlyCountries={["gh"]}
                          name="phone"
                          value={state?.phone}
                          onChange={(phone) => setState({ phone })}
                        /> */}
                        {/* <Field
                          type=""
                          className="form-control"
                          onChange={(e) => setback(e?.target?.files[0])}
                          name="second_phone"
                          placeholder="second_phone"
                        />
                        <ErrorMessage
                          name="second_phone"
                          component="div"
                          className="text-danger"
                        /> */}
                      {/* </div> */}

                      <div className="form-group">
                        <p>Selfie</p>
                        <input
                          type="file"
                          className="form-control"
                          // name="selfie"
                          onChange={(e) => setselfie(e?.target?.files[0])}
                          placeholder="selfie"
                        />
                        <ErrorMessage
                          name="selfie"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                    </div>

                    <Field
                      type="submit"
                      value="Verify Account"
                      name="submit"
                      className="btn btn--primary"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Verify;
