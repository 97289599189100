import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function HeroSliderAbout() {
  const swiperRef = useRef(null);
  const goNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="carousel slider-navs">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        navigation={{
          prevEl: ".owl-prev",
          nextEl: ".owl-next",
        }}
        ref={swiperRef}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="slide--item bg-overlay bg-overlay-dark2 bg-section">
            <div className="bg-section">
              <img
                style={{ width: "100%", opacity: "0.1", height: "100%" }}
                src="/assets/images/page-titles/1.jpg"
                alt="background"
              />
            </div>
          </div>
        </SwiperSlide>
        
        <div className="owl-controls" style={{display:"none"}}>
          <div className="owl-nav">
            <div className="owl-prev" onClick={goPrev}>
              prev
            </div>
            <div className="owl-next" onClick={goNext}>
              next
            </div>
          </div>
          <div className="owl-dots" style={{ display: "none" }} />
        </div>
      </Swiper>
    </div>
  );
}

export default HeroSliderAbout;
