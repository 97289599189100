import React from 'react'
import Layout from '../../Component/Layout/Layout'
import Store from '../../Component/Store/Store'
import Banner from '../../Component/Banner'

function Stores() {
  return (
   <>
   <Layout>
    <Banner/>
    <Store/>
   </Layout>
   </>
  )
}

export default Stores