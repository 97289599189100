import React from "react";

// import Detail from "../Component/Detail";
import Layout from "../Component/Layout/Layout";
import HeaderHello from "../Component/HeaderHello";

function Details() {
  return (
    <Layout>
      <div className="top-res">
        <HeaderHello />
      </div>
    </Layout>
  );
}

export default Details;
