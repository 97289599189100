
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import SocialSidebar from "./SocialSidebar";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../Config/AxiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Layout from "./Layout/Layout";

function Changepassword() {
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    role: Yup.string().required("Role is required"),
    username: Yup.string().required("Username is required"),
    w_phone: Yup.string().matches(
      /^[0-9]{10}$/,
      "Work phone must be 10 digits"
    ),
    country: Yup.string(),
    region: Yup.string(),
    district: Yup.string(),
    city: Yup.string(),
    suburb: Yup.string(),
    comments: Yup.string(),
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    role: "",
    username: "",
    w_phone: "",
    country: "",
    region: "",
    district: "",
    city: "",
    suburb: "",
    comments: "",
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    // Handle form submission
  };

  console.log(data, "data");
  const navigate = useNavigate();
  const GetData = () => {
    axiosInstance
      .get("profile", {
        headers: { Authorization: `Bearer ${localStorage.getItem("Token")}` },
      })
      .then((res) => {
        if (res?.data?.status === true) {
          setData(res?.data?.user);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetData();
    const token = localStorage.getItem("Token");
    if (!token) {
      navigate("/");
    } else {
      navigate("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Layout>

    

      <section
        id="user-profile"
        className="user-profile"
        style={{ marginTop: "0px" }}
      >
        <div className="container">
          <div className="row">
            <SocialSidebar />

            <div className="col-xs-12 col-sm-12 col-md-8">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form className="mb-0">
                   
               
                    <div className="form-box">
                      <h4 className="form--title">Change Password</h4>
                      <div className="form-group">
                        <Field
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          type="password"
                          className="form-control"
                          name="confirm-password"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>

                    <input
                      type="submit"
                      value="Save Edits"
                      name="submit"
                      className="btn btn--primary"
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
      </Layout>
    </div>
  );
}

export default Changepassword;
