import React from "react";

function AboutSection({ Data }) {
  return (
    <section id="about" className="about bg-white">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-5 col-md-5">
            <div className="about--img">
              <img
                className="img-responsive"
                src={Data?.image}
                alt="about img"
              />
            </div>
          </div>
          {/* .col-md-5 */}
          <div className="col-xs-12 col-sm-7 col-md-6 col-md-offset-1">
            <div dangerouslySetInnerHTML={{ __html: Data?.text_five }} />
            {/* .about-panel end */}
          </div>
          {/* .col-md-6 */}
        </div>
        {/* .row */}
      </div>
      {/* .container */}
    </section>
  );
}

export default AboutSection;
