// Loader.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const GalleryLoader = () => {
  return (
    <div className="loader-container">
      <Skeleton style={{ width: '100%', height: '388px' }} />
      <div className="thumbnail-loader flex" >
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="thumbnail-skeleton">
            <Skeleton style={{ width: '130px', height: '90px',marginLeft:"3px" }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GalleryLoader;
