/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "./Practise.css";
import { Link, useLocation } from "react-router-dom";
// import { Link } from "react-router-dom";

const countries = [
  {
    name: "Ghana",
    flag: "https://flagcdn.com/w320/gh.png",
    // status: "coming soon...",
    link: "/en-gh",
  },
  {
    name: "Nigeria",
    flag: "https://flagcdn.com/w320/ng.png",
    status: "coming soon...",
  },
  {
    name: "Kenya",
    flag: "https://flagcdn.com/w320/ke.png",
    status: "coming soon...",
  },
  {
    name: "Uganda",
    flag: "https://flagcdn.com/w320/ug.png",
    status: "coming soon...",
  },
  {
    name: "Rwanda",
    flag: "https://flagcdn.com/w320/rw.png",
    status: "coming soon...",
  },
  {
    name: "South Africa",
    flag: "https://flagcdn.com/w320/za.png",
    status: "coming soon...",
  },
];
// const navigate = useNavigate();

function Practise() {
  const location = useLocation();
  console.log(location?.pathname);
  if (location?.pathname === "/") {
    window.location.href = "/en-gh";
    // navigate("/en-gh");
  }
  return (
    <div className="App-one">
      <header className="header-one">
        <img
          // className="logo-light"
          style={{ width: "366px", height: "100px" }}
          src="/assets/images/logo-white.png"
          alt="Land Logo"
        />
        <p className="p">
          Rent with Confidence, Live in Comfort.
          <br />
          Discover millions of rooms, houses, and apartments for rent.
        </p>
        {/* <p>Discover millions of rooms, houses, and apartments for rent.</p> */}
      </header>
      <main>
        <h2 className="h2-one">Select Your Country</h2>
        <div className="countries">
          {countries.map((country) => (
            <div className="country" key={country.name}>
              <Link to={country?.link}>
                <img
                  src={country.flag}
                  className="img-coun"
                  alt={country.name}
                />
                <p className="country-name">
                  {country.name} {country?.status ? `(${country?.status})` : ""}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </main>
      <footer className="footer">
        <hr />
        <p>
          &copy; 2024 Lispria Corporation (DEA RentisReady). All Rights
          Reserved.
        </p>
      </footer>
    </div>
  );
}

export default Practise;
