import React, { useState } from 'react';
import { axiosInstance } from '../../Config/AxiosInstance';
import { toast } from 'react-toastify';

function ReportListing({ id }) {
    const [comment, setComment] = useState(""); 
    const token = localStorage.getItem("Token");
    const data = JSON.parse(localStorage.getItem("user"));
    const disabled = data?.is_verified !== '1' || !token; // Updated condition

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission

        const formData = new FormData();
        formData.append("message", comment);
        formData.append("listing_id", id);

        axiosInstance
            .post("/listing-report", formData)
            .then((res) => {
                if (res?.data?.status === true) {
                    toast.success(res?.data?.message);
                    setComment('');
                } else {
                    toast.error(res?.data?.message);
                }
            })
            .catch((err) => {
                console.error("Error in submitting report", err?.response?.data?.message);
            });
    };

    return (
        <div className="widget widget-request">
            <div className="widget--title">
                <h5>Report This Listing</h5>
            </div>
            <div className="widget--content">
                <form className="mb-0" onSubmit={handleSubmit}> {/* Added onSubmit handler */}
                    <div className="form-group">
                        <label htmlFor="message">Message*</label>
                        <textarea
                            className="form-control"
                            name="contact-message"
                            id="message"
                            maxLength={200}
                            value={comment} // Set the value from state
                            onChange={handleCommentChange} // Update state on change
                            rows={2}
                            placeholder="(optional)"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <button type="submit" className="btn btn--primary" disabled={disabled}> {/* Disabled based on condition */}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ReportListing;
