// Whatsapp.js
import React from "react";
import "./ScrollToTop.css"; // Import your CSS for styling

const Whatsapp = () => {
  // const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 0) {
  //       setIsVisible(true);
  //     } else {
  //       setIsVisible(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const scrollToTop = () => {
    window.location.href='https://api.whatsapp.com/send?phone=233244622816'
  };

  return (
    // isVisible && (
      <button className="scroll-to-top1" onClick={scrollToTop}>
   <i class="fa fa-whatsapp" style={{fontSize:"30px", color:"white"}}></i>
      </button>
    // )
  );
};

export default Whatsapp;
