import React, { useEffect, useState } from "react";

import Hero from "../Component/Hero";
// import Latest from '../Component/Latest';
// import PropertyBy from "../Component/PropertyBy";
import SimpleStep from "../Component/SimpleStep";
// import TrustedAgent from "../Component/TrustedAgent";
// import Findus from "../Component/Findus";
import Layout from "../Component/Layout/Layout";
// import PropertyByone from "../Component/PropertyByone";
// import PropertyBytwo from "../Component/PropertyBytwo";
import ScrollToTop from "../Component/Scroltotop";
// import Accra from "../Component/Front/Front";
// import Tema from "../Component/Front/Tema";
// import Ashaiman from "../Component/Front/Ashaiman";
// import Sakumono from "../Component/Front/Sakumono";
// import Capitals from "../Component/Front/Capitals";
// import DCapitals from "../Component/Front/DCapitals";
// import Popular from "../Component/Front/PopularCities";
import { axiosData } from "../Config/Axios";
import Whatsapp from "../Component/WhatsApp";
import CookieConsent from "../Component/Cokkie/Cokkies";
import Feature from "../Component/Feature/Feature";
// import { useLocation } from "react-router-dom";

function Home() {
  const [image, setImage] = useState([]);

  const GetData = () => {
    axiosData
      .get(`image-setting`)
      .then((res) => {
        if (res?.data?.status === true) {
          setImage(res?.data?.data);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    GetData();
  }, []);
  return (
    <div>
      {" "}
      <Layout>
        <Hero />
        <CookieConsent />
        <div style={{ background: "#f2f7ff" }}>
          <Feature image={image} />
        </div>
        {/* <div style={{ background: "#f2f7ff" }}>
          <PropertyBy image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Accra image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Tema image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Ashaiman image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Sakumono image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Capitals image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <DCapitals image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <Popular image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <PropertyByone image={image} />
        </div> */}
        {/* <div style={{ background: "#f2f7ff", marginTop: "20px" }}>
          <PropertyBytwo image={image} />
        </div> */}
        <SimpleStep />
        {/* <TrustedAgent /> */}
        {/* <Findus /> */}
        <ScrollToTop />
        <Whatsapp />
      </Layout>
    </div>
  );
}

export default Home;
