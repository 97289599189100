import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCapture from "./ReCapture";
import { axiosInstance } from "../../Config/AxiosInstance";
import { toast } from "react-toastify";

// Define initial values for the form fields
const initialValues = {
  contactName: "",
  contactEmail: "",
  phoneNumber: "",
  contactMessage: "",
};
// const formatPhoneNumber = (value) => {
//   // Remove all non-digit characters
//   const digits = value.replace(/\D/g, "");

//   // Apply the formatting
//   if (digits.length <= 4) {
//     return digits;
//   } else if (digits.length <= 7) {
//     return `${digits.slice(0, 4)}-${digits.slice(4)}`;
//   } else {
//     return `${digits.slice(0, 4)}-${digits.slice(4, 7)}-${digits.slice(7, 10)}`;
//   }
// };
// Define the validation schema using Yup
const validationSchema = Yup.object({
  contactName: Yup.string().required("Name is required"),
  contactEmail: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  // phoneNumber: Yup.string()
  //   .matches(/^\d{4}-\d{3}-\d{3}$/, "Phone number is not valid")
  //   .required("Phone Number is required"),
  phoneNumber: Yup.string()
    .matches(
      /^233\d{9}$/,
      "Phone number must start with 233 and be exactly 12 digits long"
    )
    .required("Phone number is required"),
  contactMessage: Yup.string().required("Message is required"),
});

const ContactC = () => {
  // const token = localStorage.getItem("Token");
  // const data1 = JSON.parse(localStorage.getItem("user"));
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const onSubmit = (data, { setSubmitting, resetForm }) => {
    if (!isCaptchaVerified) {
      toast.error("Please verify the captcha");
      setSubmitting(false);
      return;
    }
    // if (!token || !data1?.is_verified === '1') {
    //   toast.error(
    //     !token
    //       ? "Please Login First"
    //       : !data?.is_verified === 1
    //       ? "Please Verify Your Account"
    //       : ""
    //   );
    // }
    const formData = new FormData();
    setSubmitting(true);
    formData.append("name", data.contactName);
    formData.append("email", data.contactEmail);
    formData.append("phone", data?.phoneNumber);
    formData.append("message", data.contactMessage);

    axiosInstance
      .post("/contact", formData)
      .then((res) => {
        if (res?.data?.status === true) {
          toast.success(res?.data?.message);
          resetForm();
        } else {
          toast.error(res?.data?.message);
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log("Error in signup", err?.response?.data?.message);

        setSubmitting(false);
      });
  };

  return (
    <section id="contact2" className="contact contact-2 mtt-10 bg-white">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="heading heading-2 mb-50">
              <h2 className="heading--title">Contact Us</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ errors, touched, isSubmitting, setFieldValue }) => (
                <Form className="mb-0">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="contactName">Your Name*</label>
                        <Field
                          type="text"
                          className={`form-control ${
                            touched.contactName && errors.contactName
                              ? "is-invalid"
                              : ""
                          }`}
                          name="contactName"
                          id="contactName"
                        />
                        <ErrorMessage
                          component="div"
                          className="invalid-feedback"
                          name="contactName"
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="contactEmail">Email Address*</label>
                        <Field
                          type="email"
                          className={`form-control ${
                            touched.contactEmail && errors.contactEmail
                              ? "is-invalid"
                              : ""
                          }`}
                          name="contactEmail"
                          id="contactEmail"
                        />
                        <ErrorMessage
                          component="div"
                          className="invalid-feedback"
                          name="contactEmail"
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4">
                      <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <Field
                          type="text"
                          id="phone"
                          className="form-control"
                          name="phoneNumber"
                          placeholder="Enter Number"
                          maxLength="12"
                          onChange={(e) => {
                            let value = e.target.value.replace(/\D/g, "");
                            if (!value.startsWith("233")) {
                              // eslint-disable-next-line prefer-template
                              value = "233" + value.slice(3);
                            }
                            if (value.length > 12) {
                              value = value.slice(0, 12);
                            }
                            setFieldValue("phoneNumber", value);
                          }}
                        />
                        <ErrorMessage
                          component="div"
                          className="invalid-feedback"
                          name="phoneNumber"
                        />
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="form-group">
                        <label htmlFor="contactMessage">Message*</label>
                        <Field
                          as="textarea"
                          className={`form-control ${
                            touched.contactMessage && errors.contactMessage
                              ? "is-invalid"
                              : ""
                          }`}
                          name="contactMessage"
                          id="contactMessage"
                          rows={2}
                        />
                        <ErrorMessage
                          component="div"
                          className="invalid-feedback"
                          name="contactMessage"
                        />
                        <div style={{ position: "relative", top: "20px" }}>
                          <ReCapture onCaptchaChange={setIsCaptchaVerified} />
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <button
                        type="submit"
                        className="btn btn--primary"
                        disabled={!isCaptchaVerified || isSubmitting}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactC;
