/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export default function Join() {
  return (
    <section
    id="cta"
    className="cta cta-1 text-center bg-overlay bg-overlay-dark pt-90 bg-section"
    style={{ backgroundImage: 'url("assets/images/cta/bg-1.jpg")' }}
  >
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
          <h3>
            Join our professional team &amp; agents to start selling your house
          </h3>
          <a href="#" className="btn btn--primary">
            Contact
          </a>
        </div>
        {/* .col-md-6 */}
      </div>
      {/* .row */}
    </div>
    {/* .container */}
  </section>
  
  )
}
