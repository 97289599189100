/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Layout from "../Layout/Layout";
import { useLocation } from "react-router-dom";

function Blogdetail() {
  const { state } = useLocation();
  console.log(state);
  return (
    <Layout>
      <div id="blog" className="blog blog-single mt-70">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8">
              {/* Blog Entry */}
              <div className="blog-entry">
                <div className="entry--img">
                  <img
                    style={{ height: "auto", width: "auto" }}
                    src={state?.image}
                    alt="entry image"
                  />
                </div>
                <div className="entry--content">
                  <div className="entry--meta">
                    <a href="#">{state?.created_at}</a>
                  </div>
                  <div className="entry--title">
                    <h4>{state?.title}</h4>
                  </div>
                  <div className="entry--bio">
                    <div
                      dangerouslySetInnerHTML={{ __html: state?.description }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Blogdetail;
