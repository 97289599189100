/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

function TrustAgent() {
  return (
<section id="agents" className="agents bg-white pt-90">
  <div className="container">
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12">
        <div className="heading heading-2 text-center mb-70">
          <h2 className="heading--title">Trusted Agents</h2>
          <p className="heading--desc">
            Duis aute irure dolor in reprehed in volupted velit esse dolore
          </p>
        </div>
        {/* .heading end */}
      </div>
      {/* .col-md-12 end */}
    </div>
    {/* .row end */}
    <div className="row">
      {/* agent #1 */}
      <div className="col-xs-12 col-sm-4 col-md-4">
        <div className="agent">
          <div className="agent--img">
            <img src="assets/images/agents/grid/1.png" alt="agent" />
            <div className="agent--details">
              <p>
                Lorem ipsum dolor sit amet, consece adipisicing elit, sed do
                eiusmod tempor incididunt ut labore dolore.
              </p>
              <div className="agent--social-links">
                <a href="#">
                  <i className="fa fa-facebook" />
                </a>
                <a href="#">
                  <i className="fa fa-twitter" />
                </a>
                <a href="#">
                  <i className="fa fa-dribbble" />
                </a>
                <a href="#">
                  <i className="fa fa-linkedin" />
                </a>
              </div>
            </div>
          </div>
          {/* .agent-img end */}
          <div className="agent--info">
            <h5 className="agent--title">Steve Martin</h5>
            <h6 className="agent--position">Buying Agent</h6>
          </div>
          {/* .agent-info end */}
        </div>
        {/* .agent end */}
      </div>
      {/* .col-md-4 end */}
      {/* agent #2 */}
      <div className="col-xs-12 col-sm-4 col-md-4">
        <div className="agent">
          <div className="agent--img">
            <img src="assets/images/agents/grid/2.png" alt="agent" />
            <div className="agent--details">
              <p>
                Lorem ipsum dolor sit amet, consece adipisicing elit, sed do
                eiusmod tempor incididunt ut labore dolore.
              </p>
              <div className="agent--social-links">
                <a href="#">
                  <i className="fa fa-facebook" />
                </a>
                <a href="#">
                  <i className="fa fa-twitter" />
                </a>
                <a href="#">
                  <i className="fa fa-dribbble" />
                </a>
                <a href="#">
                  <i className="fa fa-linkedin" />
                </a>
              </div>
            </div>
          </div>
          {/* .agent-img end */}
          <div className="agent--info">
            <h5 className="agent--title">Mark Smith</h5>
            <h6 className="agent--position">Selling Agent</h6>
          </div>
          {/* .agent-info end */}
        </div>
        {/* .agent end */}
      </div>
      {/* .col-md-4 end */}
      {/* agent #3 */}
      <div className="col-xs-12 col-sm-4 col-md-4">
        <div className="agent">
          <div className="agent--img">
            <img src="assets/images/agents/grid/3.png" alt="agent" />
            <div className="agent--details">
              <p>
                Lorem ipsum dolor sit amet, consece adipisicing elit, sed do
                eiusmod tempor incididunt ut labore dolore.
              </p>
              <div className="agent--social-links">
                <a href="#">
                  <i className="fa fa-facebook" />
                </a>
                <a href="#">
                  <i className="fa fa-twitter" />
                </a>
                <a href="#">
                  <i className="fa fa-dribbble" />
                </a>
                <a href="#">
                  <i className="fa fa-linkedin" />
                </a>
              </div>
            </div>
          </div>
          {/* .agent-img end */}
          <div className="agent--info">
            <h5 className="agent--title">Ryan Printz</h5>
            <h6 className="agent--position">Real Estate Broker</h6>
          </div>
          {/* .agent-info end */}
        </div>
        {/* .agent end */}
      </div>
      {/* .col-md-4 end */}
    </div>
  </div>
</section>

  )
}

export default TrustAgent