import React from "react";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useState } from "react";
import { useEffect } from "react";

function Faq() {
  const [Data, setdata] = useState([]);
  const [, setloader] = useState(false);
  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get("/faq")
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);
  return (
    <section id="accordion1" className="bg-white">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <div className="heading text-center mb-75">
              <h2 className="heading--title">Asked Questions</h2>
              <p className="heading--desc">
                Duis aute irure dolor in reprehed in volupted velit esse dolore
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-7 col-md-7">
            <div className="accordion accordion-1" id="accordion01">
              {Data?.map((e) => {
                return (
                  <div className="panel">
                    <div className="panel--heading">
                      <a
                        className="accordion-toggle"
                        data-toggle="collapse"
                        data-parent="#accordion01"
                        href="#collapse01-1"
                      >
                    {e?.question}
                      </a>
                    </div>
                    <div
                      id="collapse01-1"
                      className="panel--body panel-collapse collapse in"
                    >
                      <p>
                 {e?.answer}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* End .Accordion*/}
          </div>
          {/* .col-md-7 end */}
          <div className="col-xs-12 col-sm-5 col-md-4">
            <img src="/assets/images/accordion/1.jpg" alt="img" />
          </div>
          {/* .col-md-4 end */}
        </div>
        {/* .row end */}
      </div>
      {/* .container end */}
    </section>
  );
}

export default Faq;
