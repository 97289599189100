/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { axiosInstance } from "../Config/AxiosInstance";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

import {
  // allowed,
  // amenities,
  GetData,
  GetDataone,
  Gettitle,
  // types,
  //  amenities
} from "./Aminittes";
import SocialSidebar from "./SocialSidebar";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { GoogleMap, MarkerF } from "@react-google-maps/api";
const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex:3
  }),
};
function EditProperty() {
  const { state } = useLocation();
  const [selectedState, setSelectedState] = useState({
    value: state?.region,
    label: state?.region,
  });

  const [, setTableData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [, setDistricts] = useState([]);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState({
    value: state?.city,
    label: state?.city,
  });
  const [selectedDistirct, ] = useState({
    value: state?.district,
    label: state?.district,
  });
  // console.log(selectedCountry?.value);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);

  const geonamesUsername = "sywyj";
  useEffect(() => {
    GetData().then((e) => {
      setfeature(e?.data?.data);
    });
    GetDataone().then((e) => {
      settype(e?.data?.data);
    });
    Gettitle().then((e) => {
      settitle(e?.data?.data);
    });
  }, []);
  // const countryOptions = [
  //   {
  //     value: 2300660,
  //     label: 'Ghana',
  //   },
  // ];

  useEffect(() => {
    const fetchStates = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://secure.geonames.org/childrenJSON?geonameId=${2300660}&username=${geonamesUsername}`
        );
        const stateOptions = response.data.geonames.map((state) => ({
          value: state.geonameId,
          label: state.name,
        }));
        setStates(stateOptions);
      } catch (error) {
        setError("Error fetching states");
        console.error("Error fetching states:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStates();
  }, []);

  useEffect(() => {
    if (selectedState) {
      const fetchCities = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedState.value}&username=${geonamesUsername}`
          );
          const cityOptions = response.data.geonames.map((city) => ({
            value: city.geonameId,
            label: city.name,
          }));
          setCities(cityOptions);
        } catch (error) {
          setError("Error fetching cities");
          console.error("Error fetching cities:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchCities();
    } else {
      setCities([]);
      setDistricts([]);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity) {
      const fetchDistricts = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://secure.geonames.org/childrenJSON?geonameId=${selectedCity.value}&username=${geonamesUsername}`
          );
          const districtOptions = response.data.geonames.map((district) => ({
            value: district.geonameId,
            label: district.name,
          }));
          setDistricts(districtOptions);
        } catch (error) {
          setError("Error fetching districts");
          console.error("Error fetching districts:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchDistricts();
    } else {
      setDistricts([]);
    }
  }, [selectedCity]);

  // const [selectedValues, setSelectedValues] = useState({});
  // console.log(datas, "datas");
  // const handleSelectChange = (e) => {
  //   setSelectedValues({
  //     ...selectedValues,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get("/country");
        setTableData(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching initial data", error);
      }
    };

    fetchInitialData();
  }, []);

  const [selectedAmenities, setSelectedAmenities] = useState([]);

  const maxNumber = 70; // Maximum number of images allowed
  const [images, setImages] = useState(state?.listing_images);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setSelectedAmenities([...selectedAmenities, id]);
    } else {
      setSelectedAmenities(selectedAmenities.filter((item) => item !== id));
    }
  };
  const [feature, setfeature] = useState([]);
  const [type, settype] = useState([]);
  const [title, settitle] = useState([]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Property Title is required"),
    price: Yup.string().required("Price is required"),
    description: Yup.string().required("Property Description is required"),
    type: Yup.string().required("Type is required"),
    rent_period: Yup.string().required("rent_period is required"),
    availability: Yup.string().required("availability is required"),
    no_of_bathroom: Yup.string(),
    garage: Yup.string(),
    sq_ft: Yup.string(),
    no_of_bedroom: Yup.string(),
    suburb: Yup.string(),
    address: Yup.string(),
    lease_term: Yup.string().required("Lease Term is required"),
  });
  const Navigate = useNavigate();
  const initialValues = {
    title: state?.title || "",
    description: state?.description || "",
    no_of_bathroom: state?.no_of_bathroom || "",
    garage: state?.garage || "",
    no_of_bedroom: state?.no_of_bedroom || "",
    sq_ft: state?.sq_ft || "",
    price: state?.price || "",
    rent_period: state?.rent_period || "",
    availability: state?.availability || "",
    lease_term: state?.lease_term || "",
    type: state?.type || "",
    country: state?.country || "",
    region: state?.region || "",
    address: state?.address || "",
    city: state?.city || "",
    suburb: state?.suburb || "",
    district: state?.district || "",
  };
  // console.log(selectedDistirct);
  // console.log(selectedCity);
  // console.log(selectedState);
  const token = localStorage.getItem("Token");
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const Data = new FormData();
    Data.append("title", values.title);
    Data.append("description", values.description);
    Data.append("price", values.price);
    Data.append("sq_ft", values.sq_ft);
    Data.append("no_of_bedroom", values.no_of_bedroom);
    Data.append("no_of_bathroom", values.no_of_bathroom);
    Data.append("garage", values.garage);
    Data.append("type", values.type);
    Data.append("rent_period", values.rent_period);
    Data.append("availability", values.availability);
    Data.append("country", "Ghana");
    Data.append("district", selectedDistirct.label);
    Data.append("region", selectedState.label);
    Data.append("city", values?.city);
    // Data.append("region", selectedValues.region);
    Data.append("address", values.address);
    // Data.append("district", selectedValues.district);
    Data.append("amenities", JSON.stringify(selectedAmenities));
    Data.append("lease_term", values.lease_term);
    // Data.append("city", selectedValues.city);
    Data.append("suburb", values.suburb);
    const image = [];
    images?.map((item) => {
      if ((item?.file && item?.id) || (item?.file && item?.id === undefined)) {
        image.push(item?.file);
      }
    });
    image?.map((item, index) => {
      Data.append(`images[${index}][file]`, item);
    });

    try {
      await axios
        .post(`https://api.rentisready.com/api/listing/${state?.id}`, Data, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "X-Http-Method-Override": "PUT",
          },
        })
        .then((response) => {
          toast.success(response?.data?.message);
          if (response?.data?.status === true) {
            Navigate(-1);
          }
        });
    } catch (error) {
      toast.error(error?.message);
      console.error(error);
    }
  };
  const onImageRemoves = (index, image) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    axiosInstance
      .delete(`/listing-image-destroy/${image?.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section
      id="add-property"
      className="add-property"
      style={{ marginTop: "0px" }}
    >
      <div className="container">
        <div className="row">
          <SocialSidebar />
          <div className="col-xs-12 col-sm-12 col-md-8">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className="mb-0">
                  <div className="form-box">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <h4 className="form--title">Property Description</h4>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="property-title">
                            Property Title*
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.title && touched.title ? "is-invalid" : ""
                            }`}
                            id="select-Title"
                            name="title"
                          >
                            <option value="">Select Title</option>
                            {title.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>

                          <ErrorMessage
                            name="title"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="property-description">
                            Property Description*
                          </label>
                          <Field
                            as="textarea"
                            className={`form-control ${
                              errors.description && touched.description
                                ? "is-invalid"
                                : ""
                            }`}
                            name="description"
                            id="property-description"
                            rows={2}
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-type">Type*</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.type && touched.type ? "is-invalid" : ""
                            }`}
                            id="select-type"
                            name="type"
                          >
                            <option value="">Select Type</option>
                            {type.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="type"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-Rent Period">
                            Rent Period*
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.rent_period && touched.rent_period
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-type"
                            name="type"
                          >
                            <option value="">Select Rent Period</option>
                            {type.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="rent_period"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-Availability">
                            Availability*
                          </label>
                          <Field
                            as="select"
                            className={`form-control ${
                              errors.availability && touched.availability
                                ? "is-invalid"
                                : ""
                            }`}
                            id="select-type"
                            name="type"
                          >
                            <option value="">Select availability</option>
                            {type.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="availability"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="price">Price*</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.price && touched.price ? "is-invalid" : ""
                            }`}
                            name="price"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="price"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="price">Bathroom</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.no_of_bathroom && touched.no_of_bathroom
                                ? "is-invalid"
                                : ""
                            }`}
                            name="no_of_bathroom"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="no_of_bathroom"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="garage">Garage</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.garage && touched.garage
                                ? "is-invalid"
                                : ""
                            }`}
                            name="garage"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="garage"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="bed">Bed</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.no_of_bedroom && touched.no_of_bedroom
                                ? "is-invalid"
                                : ""
                            }`}
                            name="no_of_bedroom"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="no_of_bedroom"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="bed">Area sq_ft</label>
                          <Field
                            type="number"
                            className={`form-control ${
                              errors.sq_ft && touched.sq_ft ? "is-invalid" : ""
                            }`}
                            name="sq_ft"
                            id="property-price"
                            required=""
                          />
                          <ErrorMessage
                            name="sq_ft"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="select-type">State*</label>
                          <div className="select--box">
                            <i className="fa fa-angle-down" />
                            <Select
                              placeholder="Select State/Region"
                              options={states}
                              styles={selectStyles}
                              defaultValue={selectedState}
                              onChange={setSelectedState}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="District">District*</label>
                          <div className="select--box">
                            <i className="fa fa-angle-down" />
                            <Select
                              placeholder="Select District"
                              options={cities}
                              defaultValue={selectedDistirct}
                              onChange={setSelectedCity}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="city">City/Town*</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.city && touched.city ? "is-invalid" : ""
                            }`}
                            name="city"
                            id="city"
                            disabled={selectedCity === null}
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-4">
                        <div className="form-group">
                          <label htmlFor="suburb">Suburb*</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.suburb && touched.suburb
                                ? "is-invalid"
                                : ""
                            }`}
                            name="suburb"
                            id="suburb"
                          />
                          <ErrorMessage
                            name="suburb"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="col-xs-12 col-sm-4 col-md-8">
                        <div className="form-group">
                          <label htmlFor="address">Street And LandMark</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              errors.address && touched.address
                                ? "is-invalid"
                                : ""
                            }`}
                            name="address"
                            placeholder="Street And LandMark (Opitional)"
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4 col-md-12">
                        <div className="form-group">
                          <label htmlFor="Lease Term">Lease Term*</label>
                          <Field
                            as="textarea"
                            className={`form-control ${
                              errors.lease_term && touched.lease_term
                                ? "is-invalid"
                                : ""
                            }`}
                            rows={2}
                            name="lease_term"
                            id="leaseterm"
                          />
                          <ErrorMessage
                            name="lease_term"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="form-box ">
                        <div className="row ">
                          <div className="col-xs-12 col-sm-12 col-md-12 mt-20">
                            <h4 className="form--title">Amenities</h4>
                          </div>
                          {feature?.map((amenity) => (
                            <div
                              key={amenity.name}
                              className="col-xs-12 col-sm-6 "
                            >
                              <div className="input-checkbox">
                                <label className="label-checkbox">
                                  <span>{amenity.name}</span>
                                  <input
                                    type="checkbox"
                                    id={amenity.name}
                                    onChange={handleCheckboxChange}
                                    checked={selectedAmenities.includes(
                                      amenity.name
                                    )}
                                  />
                                  <span className="check-indicator" />
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="form-box">
                        <ImageUploading
                          multiple
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                          acceptType={["jpg"]}
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            <>
                              <div className="between">
                                <div className="form--title1 flex">
                                  Property Pictures{" "}
                                  <p> (minimum 5 And Maximum 20)</p>
                                </div>
                                <button
                                  className="btn btn--primary btn-file "
                                  onClick={onImageUpload}
                                  type="button"
                                >
                                  Upload
                                </button>
                              </div>
                              <div className="upload--img-row">
                                <div className="upload--img-area">
                                  {imageList.map((image, index) => (
                                    <div key={index} className="preview--img">
                                      <img
                                        src={image["data_url"] || image?.image}
                                        id={`output--img-${index + 1}`}
                                        className="output--img"
                                        alt={`Profile ${index + 1}`}
                                      />
                                      <button
                                        style={{
                                          marginTop: "10px",
                                          background: "white",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          onImageRemoves(index, image)
                                        }
                                      >
                                        <i
                                          class="fa fa-trash-o"
                                          style={{
                                            color: "red",
                                            fontSize: "30px",
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                  ))}
                                  {imageList.length !== 0 && (
                                    <button
                                      onClick={onImageRemoveAll}
                                      style={{ marginLeft: "20px" }}
                                    >
                                      Delete All{" "}
                                      <i
                                        class="fa fa-trash-o"
                                        style={{
                                          color: "red",
                                          fontSize: "20px",
                                        }}
                                      ></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </ImageUploading>
                      </div>
                      {/* Other form fields... */}
                      <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="form-group">
                          <button type="submit" className="btn btn--primary">
                            {isSubmitting
                              ? "Adding Property..."
                              : "Add Property"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EditProperty;
