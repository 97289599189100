/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function Store() {
  return (
    <section id="blog" className="blog blog-grid">
      <div className="container">
        <div className="row mb-50">
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store1.jpg" target="_blank">
                  <img src="assets/images/store/store1.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  Free Pen. Terms and conditions apply.
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store2.jpg" target="_blank">
                  <img src="assets/images/store/store2.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  Free Pen. Terms and conditions apply.
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store3.jpg" target="_blank">
                  <img src="assets/images/store/store3.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Quality Apts., Unbeatable Prices. </a>
                  </h4>
                </div>
                <div className="entry--bio">
                  Free T-Shirt. Terms and conditions apply.
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store4.jpg" target="_blank">
                  <img src="assets/images/store/store4.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Find Your Perfect Home Today!</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  Free T-Shirt. Terms and conditions apply.
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
          {/* Blog Entry #4 */}
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div className="blog-entry">
              <div className="entry--img">
                <a href="assets/images/store/store.jpg" target="_blank">
                  <img src="assets/images/store/store.jpg" alt="entry image" />
                </a>
              </div>
              <div className="entry--content">
                <div className="entry--meta">
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                  <span className="fa fa-star" />
                </div>
                <div className="entry--title">
                  <h4>
                    <a href="#">Viera Pen with Rose Gold Trim</a>
                  </h4>
                </div>
                <div className="entry--bio">
                  A viral video is one that creatively
                </div>
                <div className="entry--more">
                  <a href="#">
                    Shop Now
                    <i className="fa fa-shopping-cart" />
                  </a>
                </div>
              </div>
            </div>
            {/* .blog-entry end */}
          </div>
          {/* .col-md-4 end */}
        </div>
        {/* .col-md-4 end */}
      </div>
    </section>
  );
}

export default Store;
