/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useEffect, useState } from "react";
import { GetAds } from "../Aminittes";

function Ads() {
  const [data, setdata] = useState([]);
  useEffect(() => {
    GetAds().then((e) => setdata(e?.data?.data));
  }, []);
  return (
    <Fragment>
      {data?.map((e) => {
        return (
          <div
            className="widget widget-mortgage-calculator"
            style={{ width: "360px" }}
          >
            <div className="widget--content">
              <a target="_blank" href={e?.link}>
                <img src={e?.image} style={{ width: "100%" }} />
              </a>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}

export default Ads;
