/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useState } from "react";
import { useEffect } from "react";
import LoaderCard from "../Loader/LoaderCard";
import { useNavigate } from "react-router-dom";

function Blog() {
  const [Data, setdata] = useState([]);
  const [loader, setloader] = useState(false);
  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get("/blog")
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(() => {
    GetAllAdmin();
  }, []);
  const navigate = useNavigate();
  return (
    <section id="blog" className="blog blog-grid">
      <div className="container">
        <div className="row mb-50">
          {loader ? (
            <div className="flex">
              <LoaderCard />
            </div>
          ) : (
            Data?.map((e) => {
              return (
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <div className="blog-entry">
                    <div
                      className="entry--img"
                      onClick={() =>
                        navigate(`/blogdetail/${e?.id}`, { state: e })
                      }
                    >
                      <img src={e?.image} alt="entry image" />
                    </div>
                    <div className="entry--content">
                      <div className="entry--meta">
                        <a href="#">{e?.created_at}</a>
                      </div>
                      <div className="entry--title">
                        <h4>
                          <a href="#">{e?.title}</a>
                        </h4>
                      </div>
                      <div className="entry--bio">
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: e?.description.slice(0, 50),
                          }}
                        />
                      </div>
                      {/* <div className="entry--more">
                      <a href="#">
                        Read More
                        <i className="fa fa-angle-double-right" />
                      </a>
                    </div> */}
                    </div>
                  </div>
                  {/* .blog-entry end */}
                </div>
              );
            })
          )}
        </div>

        {/* <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 clearfix text--center">
        <ul className="pagination">
          <li className="active">
            <a href="#">1</a>
          </li>
          <li>
            <a href="#">2</a>
          </li>
          <li>
            <a href="#">3</a>
          </li>
          <li>
            <a href="#">...</a>
          </li>
          <li>
            <a href="#" aria-label="Next">
              <span aria-hidden="true">
                <i className="fa fa-angle-right" />
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div> */}
      </div>
    </section>
  );
}

export default Blog;
