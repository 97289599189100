import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({children})=>{
    const token = localStorage.getItem("Token");

    if(!token) {
        return <Navigate to="/en-gh" replace />
    }
 return children

}