import React from "react";
import Layout from "../../Component/Layout/Layout";
// import Index from '../../Component/About/AboutCom'
import AboutCom from "../../Component/About/AboutCom";
import AboutSection from "../../Component/About/AboutSection";
import WhatWeProvide from "../../Component/About/WhatWeProvide";
import TrustAgent from "../../Component/About/TrustAgent";
import Join from "../../Component/About/Join";
// import HeroSlider from "../../Component/HeroSlider";
import { axiosInstance } from "../../Config/AxiosInstance";
import { useState } from "react";
import { useEffect } from "react";

function About() {
  const [, setloader] = useState(false);
  const [Data, setdata] = useState([]);
  const GetAllAdmin = () => {
    setloader(true);
    axiosInstance
      .get("/about")
      .then((response) => {
        if (response.data.data) {
          // console.log(response?.data);
          
          setdata(response?.data?.data);
          setloader(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setloader(false);
      });
  };
  useEffect(()=>{
    window.scroll(0,0)
    GetAllAdmin();
  },[])
  return (
    <Layout>
      {/* <HeroSlider /> */}
      <AboutCom/>
      <AboutSection Data={Data} />
      <WhatWeProvide Data={Data} />
      <TrustAgent />
      <Join />
    </Layout>
  );
}

export default About;
